import React, {useEffect} from 'react';
import {Head} from '@inertiajs/react';

import CustomerHeader from "@/Pages/CustomerSection/Menu/CustomerHeader";
import HomeSlider from "@/Pages/CustomerSection/Menu/HomeSlider";
import ContentPage from "@/Pages/CustomerSection/Home/ContentPage";
import CustomerLayout from "@/Layouts/CustomerLayout";

export default function Index() {

    useEffect(() => {
        const bodyElement = document.body;
        const divElement = document.querySelector(".antialiased");

        bodyElement.classList.remove("dark-theme","blue-theme");
        divElement?.classList.remove("dark-theme","blue-theme");
        if (divElement) {
            divElement.style.paddingLeft = "0px";
            divElement.style.paddingTop = "0px";
        }

        bodyElement.classList.add("light-theme");
        divElement?.classList.add("light-theme");
    }, []);

    return (
        <>
            <CustomerLayout>

                <Head title="Customer Home Page" />



                <HomeSlider/>

                <ContentPage/>


            </CustomerLayout>
        </>
    );
}
